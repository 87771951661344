<template>
  <div class="mb-1">
    <h1 class="text-plum text-center title py-2">Memurray</h1>
  </div>
</template>
<script>
export default {
  name: "AppTitle",
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/appStyles.scss";
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");
.title,
.subtitle {
  font-family: "Cedarville Cursive", cursive;
}
</style>
