<template>
  <div class="flex items-center justify-center text-plum my-4">
    &#xA9; 2021 Bryan Yunis
  </div>
</template>
<script>
export default {
  name: "Footer",
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/appStyles.scss";
</style>
