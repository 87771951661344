<template>
  <div class="bg-pebble py-1 min-h-screen">
    <div class="xl:w-7/12 w-11/12 mx-auto mt-3 mb-8 wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss">
@import "@/assets/styles/appStyles.scss";
@import "@/assets/styles/reset.scss";
@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
</style>
